.subscriptionDetailList {
	margin-top: 1em;

	dt {
		font-weight: bold;
	}

	ul.domains {
		padding: 0;
		list-style-type: none;
	}
}

.subscriptionItem {
	.title {
		margin-bottom: 1em;
	}
}