// Provides basic structure for the whole app.
// Lifted from: https://medium.com/@gmonne/simple-app-layout-with-office-ui-fabric-react-2eac6361e1b4 on 01-30-20

.App {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100vh;

  .AppHeader {
    order: 0;
    flex-grow: 0;
    height: 48px;
  }

  .body {
    order: 1;
    flex: 1 1 auto;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    .AppSidebar {
      order: 0;
      max-width: 24em;
      flex: 0 1 24em;

      @media (min-width: 800px) {
        .collapseIcon { display: none; }
      }

      @media (max-width: 800px) {
        .ms-Nav-linkText { display: none; }
      }

      .NavCollapsable {
        &.open {
          .ms-Nav-linkText {
            display: block;
          }
        }
      }
    }

    .AppContent {
      order: 1;
      flex: 1 1 auto;
    }
  }


  .AppFooter {
    height: 24px;
  }
}